function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + 1 * 3300 * 1000);
  var expires = "expires=" + d.toUTCString();
  var maxAge = "max-age=" + 3300;
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + maxAge + ";SameSite=None; secure";
}

function deleteCookie(name) {
  var d = new Date();
  d.setTime(d.getTime() - (1 * 3300 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = name + '=; path=/;' + expires + "";
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


const cookie = {
  getCookie: getCookie,
  setCookie: setCookie,
  deleteCookie: deleteCookie
}

export default cookie;