function getEVABaseURL() {
    const currentWindow = window.location.href;

    if (currentWindow.includes('localhost')) {
        return 'https://dev-inst.eva.ecolab.com';
    } else if (currentWindow.includes('ecolabdev') || currentWindow.includes('dev-it') || currentWindow.includes('ecolabsandbox.service-now.com')) {
        return 'https://dev-it.eva.ecolab.com';
    } else if (currentWindow.includes('ecolabqa') || currentWindow.includes('stage-it')) {
        return 'https://stage-it.eva.ecolab.com';
    } else if (currentWindow.includes('https://ecolab.service-now.com') || currentWindow.includes('it.eva')) {
        return 'https://it.eva.ecolab.com';
    } else if (currentWindow.includes('inside-stage-cms') || currentWindow.includes('dev-hr') || currentWindow.includes('inside-dev-cms')) {
        return 'https://dev-hr.eva.ecolab.com';
    } else if (currentWindow.includes('hr.eva') || currentWindow.includes('inside-cms') || (currentWindow.includes('inside.ecolab.com'))) {
        return 'https://hr.eva.ecolab.com';
    } else if (currentWindow.includes('dev-inst.eva')) {
        return 'https://dev-inst.eva.ecolab.com';
    } else if (currentWindow.includes('stage-inst.eva')) {
        return 'https://stage-inst.eva.ecolab.com';
    } else if (currentWindow.includes('inst.eva')) {
        return 'https://inst.eva.ecolab.com';
    }
}

const baseURLMap = {
    getEVABaseURL: getEVABaseURL
}

export default baseURLMap;