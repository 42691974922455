const browserDetect = () => {
    const currentWindow = navigator.userAgent;

    if(currentWindow.includes('Chrome')) {
        return 'Chrome';
    } else if (currentWindow.includes('Edg')) {
        return 'MS-Edge';
    } else if (currentWindow.includes('Safari')) {
        return 'Safari'; 
    } else {
        return 'Chrome';
    }
}

export default browserDetect;