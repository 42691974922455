export const getHeaders = () => {
    try{
        const msAccessToken = window.sessionStorage.getItem('MICROSOFT_DISPATCH_ACCESS_TOKEN');
        const headers = new Headers();
        const bearer = `Bearer ${msAccessToken}`;
        headers.append("Authorization", bearer);
        return headers;
    }
    catch(error){
        console.log(error);
    }
}