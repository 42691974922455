// Helper function to call MS Graph API endpoint 
// using authorization bearer token scheme
export async function callMSGraph(endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  try {
    let response = await fetch(endpoint, options);
    response = await response.json();
    return response;
  } catch (err) {
    console.log('err', err);
  }
}

//Get User Photo using Graph API
export async function getUserPhotoData(endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };
  try {
    let response = await fetch(endpoint, options);
    // response = await response.json();

    const imgData = `data:${response.headers.get('content-type')};base64,${encode(await response.arrayBuffer())}`;
    return imgData;
  } catch (err) {
    console.log('err', err);
  }
}

function encode(arraybuffer) {
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  var bytes = new Uint8Array(arraybuffer),
    i, len = bytes.length, base64 = "";

  for (i = 0; i < len; i += 3) {
    base64 += chars[bytes[i] >> 2];
    base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += chars[bytes[i + 2] & 63];
  }

  if ((len % 3) === 2) {
    base64 = base64.substring(0, base64.length - 1) + "=";
  } else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2) + "==";
  }
  return base64;
}

export async function getUserPhotoDataFromEVA(endPoint, userID, msgraphToken) {
  let bodyObj = { userId: userID, msgraphToken: msgraphToken };
  const options = {
    method: "POST",
    body: JSON.stringify(bodyObj)
  };
  try {
    let response = await fetch(endPoint, options);
    if (response !== null && response !== undefined && response.status === 200) {
      response = await response.json();
    } else {
      response = { success: false };
    }
    return response;
  } catch (err) {
    console.log('err', err);
  }
}

export async function getUserDetails(endPoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  try {
    let response = await fetch(endPoint, options);
    response = await response.json();
    return response;
  } catch (err) {
    console.log('err', err);
  }
}

export async function getUserDataFromEVA(endPoint, userID, msgraphToken) {
  let bodyObj = { userId: userID, msgraphToken: msgraphToken };
  const options = {
    method: "POST",
    body: JSON.stringify(bodyObj)
  };
  try {
    let response = await fetch(endPoint, options);
    if (response !== null && response !== undefined && response.status === 200) {
      response = await response.json();
    } else {
      response = { success: false };
    }
    return response;
  } catch (err) {
    console.log('err', err);
  }
}

export async function getUserSavedLangFromBlobStorage(endPoint, userID) {
  let bodyObj = { email: userID.username };
  const options = {
    method: "POST",
    body: JSON.stringify(bodyObj)
  };
  try {
    let response = await fetch(endPoint, options);
    response = await response.json();
    if (response) {
      return response;
    }
    return '';
  } catch (err) {
    console.log('err', err);
  }

}