import React from 'react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import authConfig from "../authServices/authConfig";
import App from '../App';

export const BaseAppWithMSALCredential = () => {
    const [msalInstance, setMsalInstance] = React.useState(null);

    React.useEffect(() => {
        const fetchConfig = () => {
            authConfig.getEVAClient().then(res => {
                const msalConfig = authConfig.msalConfig;
                msalConfig.auth.clientId = res.clientId;
                msalConfig.auth.authority = res.authority;
                authConfig.msBotAppId = res.msBotAppId;
                let apiScope = `api://${res.msBotAppId}/user_impersonation`;
                authConfig.loginRequest.scopes.push(apiScope);
                authConfig.silentLoginRequest.scopes.push(apiScope);
                const msalInstance = new PublicClientApplication(authConfig.msalConfig);

                // Default to using the first account if no account is active on page load
                if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                    // Account selection logic is app dependent. Adjust as needed for different use cases.
                    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
                }

                // Optional - This will update account state if a user signs in from another tab or window
                msalInstance.enableAccountStorageEvents();

                msalInstance.addEventCallback((event) => {
                    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                        const account = event.payload.account;
                        msalInstance.setActiveAccount(account);
                    }
                });
                setMsalInstance(msalInstance);
            });
        }
        fetchConfig();
    }, []);

    return (
        msalInstance && <App pca={msalInstance} />
    );
}
