import React from 'react';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";

import MinimizableWebChat from './MinimizableWebChat';
import { ai } from './TelemetryService';
import './App.css';

const instrumentationKey = '17cbb157-df5c-4395-be67-e02b5b93cc33';
ai.initialize(instrumentationKey);

const App = ({ pca }) => (
  <MsalProvider instance={pca}>
    <div className="App">
      <MinimizableWebChat />
    </div>
  </MsalProvider>
);

export default App;
