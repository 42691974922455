import React, { useEffect, useMemo, useState } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet, createCognitiveServicesSpeechServicesPonyfillFactory } from 'botframework-webchat';
import baseURLMap from './apiMapMiddleware/baseURLMap';
import { getUserPhotoDataFromEVA, getUserSavedLangFromBlobStorage, getUserPhotoData } from './authServices/graph';
import './WebChat.css';
import browserDetect from './browserDetect'
import customlocalStorage from './utils/localStorage';
import $ from 'jquery';
import graphConfig from './authServices/graphConfig';
import { getHeaders } from './utils/headers';

var language = window.navigator.userLanguage || window.navigator.language;
// var language = 'en-us';
const WebChat = ({ className, onFetchToken, store, token, isloginFailure, userID, userInfo, activityMiddleware, appInsights }) => {
  const directLine = useMemo(() => {
    let conObj = customlocalStorage.getLocalStoredValue('convData');
    if (conObj && conObj.conversationId) {
      return createDirectLine({
        token: conObj.conversationToken,
        conversationId: conObj.conversationId,
        watermark: '-'
      });
    } else {
      const obj = createDirectLine(
        {
          token: token,
          watermark: '-'
        });
      obj.connectionStatus$.subscribe((status) => {
        if (obj.conversationId && obj.token) {
          const d = new Date();
          const initObj = {
            conversationId: obj.conversationId,
            conversationToken: obj.token,
            conversationExpire: d.getTime() + 1 * 3300 * 1000
          }
          customlocalStorage.setValueIntoLocalStorage('convData', initObj);
        }

      });
      return obj;
    }
  }, [token]);

  const [webSpeechPonyfillFactoryValue, setWebSpeechPonyfillFactoryValue] = useState();
  const [avatarImg, setAvatarImg] = useState({
    botAvatarImage: '',
    botAvatarInitials: 'BF',
    userAvatarImage: '',
    userAvatarInitials: ''
  });

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent'
      }),
    []
  );

  useEffect(() => {
    const userImageUsingGraphAPI = async () => {
      const isInst = $('.cds-inst') !== null && $('.cds-inst') !== undefined && $('.cds-inst').length > 0;
      const evaBaseURL = await baseURLMap.getEVABaseURL();
      const evaPhotoURL = evaBaseURL + '/api/msgraph/photo';
      const evaLangURL = evaBaseURL + '/api/msgraph/lang';
      let tempUserImgData;
      let tempUserAvatarInitials;

      if (userID) {
        tempUserAvatarInitials = userID.name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
        tempUserAvatarInitials = tempUserAvatarInitials[1] + tempUserAvatarInitials[0];
        let apilanguage = { langFlag: 'false' };
        if (!isInst) {
          apilanguage = await getUserSavedLangFromBlobStorage(evaLangURL, userID);
        }
        if (apilanguage.userSaveLang !== '' && apilanguage.langFlag === 'true') {
          language = apilanguage.userSaveLang;
        }
        const msgraphToken = window.sessionStorage.getItem('MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN');
        if (msgraphToken !== null && msgraphToken !== undefined && msgraphToken !== 'null' && msgraphToken !== 'undefined') {
          const record = await getUserPhotoData(graphConfig.graphPhotoEndpoint, msgraphToken);
          if (record !== null && record !== undefined) {
            tempUserImgData = record;
          }
        }
      }
      const styleOptionsValue = {
        botAvatarImage: '',
        botAvatarInitials: 'BF',
        userAvatarImage: tempUserImgData,
        userAvatarInitials: tempUserAvatarInitials
      };

      setAvatarImg(styleOptionsValue);
    }
    userImageUsingGraphAPI();
  }, [token, userID]);

  useEffect(() => {
    // onFetchSpeech();
    const createFetchSpeechServicesCredentials = async () => {
      let evaBaseURL = baseURLMap.getEVABaseURL();
      evaBaseURL = evaBaseURL + '/api/speechservice/token';
      const options = {
        method: "POST",
        headers: getHeaders()
      };
      const lastPromise = await fetch(evaBaseURL, options);
      const res = await lastPromise.json();
      const response = {
        credentials: {
          authorizationToken: res.token,
          region: res.region
        }
      };

      const webSpeechResponse = await createCognitiveServicesSpeechServicesPonyfillFactory(response);
      setWebSpeechPonyfillFactoryValue(() => webSpeechResponse);
    };

    createFetchSpeechServicesCredentials();
  }, [token]);

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);
  let userDetails = { displayName: 'User' };
  if (userID !== null && userID !== undefined) {
    userDetails = { id: userID.localAccountId, displayName: userID.name, mail: userID.username };
  }

  if (userInfo != undefined && userInfo != null) {
    userDetails.jobTitle = userInfo.jobTitle;
    userDetails.mobilePhone = userInfo.mobilePhone;
    userDetails.officeLocation = userInfo.officeLocation;
  }

  const userString = JSON.stringify(userDetails);
  const isInst = $('.cds-inst') !== null && $('.cds-inst') !== undefined && $('.cds-inst').length > 0;
  return token && webSpeechPonyfillFactoryValue ? (
    <ReactWebChat className={`${className || ''} web-chat`}
      directLine={directLine}
      store={store}
      activityMiddleware={activityMiddleware}
      styleSet={styleSet}
      styleOptions={avatarImg}
      webSpeechPonyfillFactory={webSpeechPonyfillFactoryValue}
      locale={language}
      username={userString} />
  ) : (isloginFailure ? (
    <div className={`${className || ''} connect-spinner`}>
      {appInsights.trackEvent({ name: 'EVALoginAttempt', properties: { browser: browserDetect(), requestDate: new Date() } })}

      {!isInst && <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <div className="Eva-alignleft">
          <p>
            Login in progress ...
          </p>
          <p>
            If you are waiting more than a few seconds on this screen, please try one of the following options:
          </p>
          <ul>
            <li>Refresh this browser window by <i> clicking on the refresh icon button on your browser, or press F5 </i> on your keyboard.</li>
            <li>Is your browser <b> blocking pop-ups </b> ? <i>Follow instructions below to unblock pop-ups </i> for Microsoft Edge browser or Google Chrome browser.</li>
          </ul>
          <b> Microsoft Edge </b>
          <ul>
            <li>At the top right, click on the "<i>three dots</i>" and click "<i>Settings</i>"</li>
            <li>Click on "<i>Cookies and site permissions</i>"</li>
            <li>Scroll down the page and locate "<i>Pop-ups and redirects</i>"</li>
            <li>Move the Block toggle to "<i>On</i>"</li>
          </ul>
          <b> Google Chrome </b>
          <ul>
            <li>At the top right, click on the "<i>three dots</i>" and then "<i>Settings</i>"</li>
            <li>Under "<i>Privacy and security</i>," click "<i>Site settings</i>"</li>
            <li>Click "<i>Pop-ups and redirects</i>"</li>
            <li>At the top, turn the setting to "<i>Allowed</i>" or Blocked.</li>
          </ul>
        </div>
      </div>}
    </div>
  ) :
    (
      <div className={`${className || ''} connect-spinner`}>
        <div className="content">
          <div className="icon">
            <span className="ms-Icon ms-Icon--Robot" />
          </div>
          <p>Please wait while we are connecting.</p>
        </div>
      </div>
    )
  );
};

export default WebChat;