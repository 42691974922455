import React, { useCallback, useEffect, useMemo, useState } from 'react';
import baseURLMap from '../apiMapMiddleware/baseURLMap';
import './ProfileMenu.css';
import { getUserPhotoDataFromEVA } from '../authServices/graph';

const MicrosoftGraphProfileMenu = ({ userId, name, onSignIn, handleSignoutEvent, msgraphToken }) => {

  const [expanded, setExpanded] = useState(false);
  const [avatarURL, setAvatarURL] = useState('/images/Microsoft-Graph-64px-DDD-White.png');
  const signedIn = onSignIn;

  useEffect(() => {
    const userImageUsingGraphAPI = async ()=> {
      const evaBaseURL = await baseURLMap.getEVABaseURL();
      const evaPhotoURL = evaBaseURL + '/api/msgraph/photo';
      let tempUserImgData = '/images/Microsoft-Graph-64px-DDD-White.png';
      if(userId && msgraphToken && msgraphToken !== 'null' && msgraphToken !== 'undefined') {
        const record = await getUserPhotoDataFromEVA(evaPhotoURL, userId, msgraphToken);
        if (record !== null && record !== undefined && record.success) {
          tempUserImgData = record.data;
        }
      }
      setAvatarURL(tempUserImgData);
    }
    userImageUsingGraphAPI();
  }, [userId]);

  // CSS style for displaying avatar as background image.
  // Background image will ease handling 404 or other HTTP errors by not showing the image.
  const avatarStyle = useMemo(() => ({
    backgroundImage: `url(${ avatarURL || '/images/Microsoft-Graph-64px-DDD-White.png' })`
  }), [avatarURL]);

  // In addition to running the sign in logic from OAuth context, we will also collapse the menu.
  const handleSignIn = useCallback(() => {
    // onSignIn && onSignIn();
    setExpanded(false);
    window.location.reload();
  });

  // In addition to running the sign in logic from OAuth context, we will also collapse the menu.
  const handleSignOut = useCallback(() => {
    // onSignOut && onSignOut();
    setExpanded(false);
    handleSignoutEvent();
  }, [handleSignoutEvent]);


  // useEffect(() => {
  //   handleSignoutEvent();
  // }, [handleSignoutEvent]);

  const handleToggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);
    return (
    <div
      aria-expanded={ expanded }
      className="sso__profile"
    >
      <button
        aria-label="Open profile menu"
        className="sso__profileAvatar"
        onClick={ signedIn ? handleToggleExpand : handleSignIn }
        style={ avatarStyle }
      >
        { signedIn && <div className="sso__profileAvatarBadge sso__profileAvatarBadge__microsoft" /> }
      </button>
      {
        signedIn && expanded &&
          <ul className="sso__profileMenu">
            {
              name &&
                <li className="sso__profileMenuItem">
                  <span>
                    Signed in as <strong>{ name }</strong>
                  </span>
                </li>
            }
            {
                <li className="sso__profileMenuItem">
                  <a
                    href="https://portal.office.com/account/#apps"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Review access on Office.com
                  </a>
                </li>
            }
            {
                <li className="sso__profileMenuItem">
                  <button className= "sso__profileMenuItemBtn"
                    onClick={ handleSignOut }
                    type="button"
                  >
                    Sign out
                  </button>
                </li>
            }
          </ul>
      }
    </div>
  );
};

export default MicrosoftGraphProfileMenu;
