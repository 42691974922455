import { getHeaders } from "./headers";

export async function getStatus(endPoint, userID) {
    let bodyObj = { userId: userID };
    const options = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(bodyObj)
    };
    try {
        let response = await fetch(endPoint, options);
        response = await response.json();
        return response;
    } catch (err) {
        console.log('err', err);
    }
}