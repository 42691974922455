// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters, 
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
import baseURLMap from './../apiMapMiddleware/baseURLMap';


const msalConfig = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

var msBotAppId = "";
// Add here the scopes to request when obtaining an access token for MS Graph API
// for more, visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', "offline_access", "email"]
};

var silentLoginRequest = {
  scopes: ["offline_access"]
  // ,loginHint: ""
};

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
  scopes: ['openid', 'profile', 'User.Read']
};


async function getEVAClient() {
  let evaBaseURL = baseURLMap.getEVABaseURL();
  evaBaseURL = evaBaseURL + '/api/aad/oauthSettings';
  try {
    const options = {
      method: 'POST'
    };

    const res = await fetch(evaBaseURL, options);
    return await res.json();;

  } catch (error) {
    console.log('error->', error);
  }

}

const authConfig = {
  tokenRequest,
  silentLoginRequest,
  loginRequest,
  msBotAppId,
  msalConfig,
  getEVAClient
};

export default authConfig;