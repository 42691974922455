import { getHeaders } from '../utils/headers';
import baseURLMap from './../apiMapMiddleware/baseURLMap';

async function getDirectlineToken(directlineUserID) {
    let evaBaseURL = await baseURLMap.getEVABaseURL();
    evaBaseURL = evaBaseURL + '/api/directline/token';
    const options = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({ userID: directlineUserID })
    };

    const res = await fetch(evaBaseURL, options);
    return await res.json();

}

const directLineServices = {
    getDirectlineToken: getDirectlineToken
};

export default directLineServices;