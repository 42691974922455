import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';
import { useMsal } from "@azure/msal-react";
import directLineServices from './directLineServices/directLineServices';
import { getUserSavedLangFromBlobStorage } from './authServices/graph';
import baseURLMap from './apiMapMiddleware/baseURLMap';
import WebChat from './WebChat';
import cookie from './utils/cookie';
import hambugerMenu from './utils/hambugerMenu';
import './fabric-icons-inline.css';
import './MinimizableWebChat.css';
import $ from 'jquery';
import * as jwtDecode from 'jwt-decode';
import { getAppInsights } from './TelemetryService';
import { getStatus } from './utils/notification';
import browserDetect from './browserDetect';
import MicrosoftGraphProfileMenu from './ui/MicrosoftGraphProfileMenu';
import authConfig from './authServices/authConfig';
import { getUserDetails } from './authServices/graph';
import graphConfig from './authServices/graphConfig';

let disp;
const MinimizableWebChat = () => {

  const { instance } = useMsal();
  const appInsights = getAppInsights();
  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [side, setSide] = useState('right');
  const [token, setToken] = useState();
  const [isloginFailure, setIsloginFailure] = useState(false);
  const [userID, setUserID] = useState();
  const [userInfo, setUserInfo] = useState();
  const [isSignout, setIsSignout] = useState(false);
  const isInst = $('.cds-inst') !== null && $('.cds-inst') !== undefined && $('.cds-inst').length > 0;
  // const [userLang, setUserLang] = useState(window.navigator.userLanguage || window.navigator.language);
  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => next => action => {
        disp = dispatch;
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          const webchatJoin = cookie.getCookie('webchatJoin');
          if (webchatJoin === null || webchatJoin === undefined || webchatJoin === '' ||
            (webchatJoin !== null && webchatJoin !== undefined && webchatJoin === 'false')) {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'webchat/join',
                value: {
                  language: window.navigator.language,
                  accessToken: window.sessionStorage.getItem('MICROSOFT_DISPATCH_ACCESS_TOKEN'),
                  userID: userID.localAccountId
                }
              }
            });
            cookie.setCookie('webchatJoin', true);
          }

        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (action.payload !== null && action.payload !== undefined &&
            action.payload.activity !== null && action.payload.activity !== undefined) {
              
              //Code for disabling the used forms in chatbot            
              if(action.payload.activity.value !== undefined)
              {
                disableUsedForms(action);
              }

              
            if (action.payload.activity.text !== null && action.payload.activity.text !== undefined &&
              (action.payload.activity.text === 'Live agent chat has been ended' || action.payload.activity.text === 'El chat del agente en vivo ha finalizado' || action.payload.activity.text === 'Live agent chat is beëindigd')) {
              // console.log('inside remove class');
              $('#openhamburgermenu').removeClass('none');
            } else if (action.payload.activity.suggestedActions !== null && action.payload.activity.suggestedActions !== undefined &&
              action.payload.activity.suggestedActions.actions !== null && action.payload.activity.suggestedActions.actions !== undefined &&
              action.payload.activity.suggestedActions.actions.length > 0) {
              let actionValue = action.payload.activity.suggestedActions.actions[0].value;
              if (actionValue === 'End Live Chat' || actionValue === 'chat final en vivo' || actionValue === 'Beëindig Live Chat') {
                $('#openhamburgermenu').addClass('none');
              }
            }
            if (action.payload.activity.text !== null && action.payload.activity.text !== undefined) {
              if (action.payload.activity.text === 'Your preferred language has been set') {
                const obj = {
                  langFlag: 'true',
                  userSaveLang: 'en'
                };
                $('#openhamburgermenu').remove();
                renderHamburger(obj);
              } else if (action.payload.activity.text === 'Se ha establecido su idioma preferido') {
                const obj = {
                  langFlag: 'true',
                  userSaveLang: 'es'
                };

                $('#openhamburgermenu').remove();
                renderHamburger(obj);
              } else if (action.payload.activity.text === 'Uw voorkeurstaal is ingesteld') {
                const obj = {
                  langFlag: 'true',
                  userSaveLang: 'nl'
                };
                $('#openhamburgermenu').remove();
                renderHamburger(obj);
              }
            }

            if (action.payload.activity.text !== null && action.payload.activity.text !== undefined) {
              if (action.payload.activity.text === 'Please click the attachment icon to upload.' || action.payload.activity.text === 'Haga clic en el icono de archivo adjunto para cargar.' ||
                action.payload.activity.text === 'Klik op het bijlagepictogram om te uploaden.' ||
                // action.payload.activity.text === 'There was an error while uploading files. EVA supports image, text, pdf, excel and document files with a size limit up to 4MB. Please check your file and try again.' || 
                (action.payload.activity.text.indexOf('4MB.') > -1)
              ) {
                if ($('.attachment_icon').length == 0) {
                    addClassToUploadAttchmentIcon();
                    setTimeout(function() {
                        $('.attachment_icon').addClass('visible');
                    }, 200)
                } else {
                    $('.attachment_icon').addClass('visible');
                }
              } else if ($('.attachment_icon').hasClass('visible') ||
                action.payload.activity.text === 'Is there anything else we should know?' ||
                action.payload.activity.text === '¿Hay algo más que debamos saber?' ||
                action.payload.activity.text === 'Is er nog iets dat we moeten weten?' ||
                action.payload.activity.text === '¿Hay algo más que deberíamos saber?') {
                $('.attachment_icon').removeClass('visible');
              }
            }

            if (action.payload.activity.text !== null && action.payload.activity.text !== undefined) {
              let text = action.payload.activity.text.toLowerCase();
              text = text.replace(/ /g, '');
              if (text === 'signin') {
                // window.location.reload(); //don not delete, need clarification
              }
            }
          }
          if (action.payload.activity.from.role === 'bot') {
            setNewMessage(true);
          }
        }

        return next(action);
      }),
    [userID, isInst]
  );
  const {
    hooks: { usePostActivity }
  } = window.WebChat;
  const BotActivityDecorator = ({ activityID, children }) => {
    const postActivity = usePostActivity();
    const [isUpActive, setIsUpActive] = useState(false);
    const [isDownActive, setIsDownActive] = useState(false);
    let replyToId = null;
    let messageType = null
    if (children.props.activity.channelId === 'directline') {
      messageType = 'message';
    }
    const handleDownvoteButton = useCallback((event) => {
      let eventTargetDownButton = event.target.classList.contains('active');
      replyToId = getDecrementedActivityId(activityID);
      if (eventTargetDownButton) {
        setIsDownActive(false);
        setIsUpActive(false);
        postActivity({
          type: messageType,
          reactionsRemoved: [{ type: 'Dislike', value: -1 }],
          replyToId: replyToId
        });
      } else {
        setIsDownActive(true);
        setIsUpActive(false);
        postActivity({
          type: messageType,
          reactionsAdded: [{ type: 'Dislike', value: -1 }],
          replyToId: replyToId
        });
      }
    }, [activityID, postActivity]);
    const handleUpvoteButton = useCallback((event) => {
      let eventTargetUpButton = event.target.classList.contains('active');
      replyToId = getDecrementedActivityId(activityID);
      if (eventTargetUpButton) {
        setIsDownActive(false);
        setIsUpActive(false);
        postActivity({
          type: messageType,
          reactionsRemoved: [{ type: 'Like', value: 1 }],
          replyToId: replyToId
        });
      } else {
        setIsDownActive(false);
        setIsUpActive(true);
        postActivity({
          type: messageType,
          reactionsAdded: [{ type: 'Like', value: 1 }],
          replyToId: replyToId
        });
      }
    }, [activityID, postActivity]);
    return (
      <div className="botActivityDecorator">
        <ul className="botActivityDecorator_buttonBar">
          <li className="thumbs_up_button">
            <button className={isUpActive ? 'botActivityDecorator_button active' : 'botActivityDecorator_button'} onClick={handleUpvoteButton}></button>
          </li>
          <li className="thumbs_down_button">
            <button className={isDownActive ? 'botActivityDecorator_button active' : 'botActivityDecorator_button'} onClick={handleDownvoteButton}></button>
          </li>
        </ul>
        <div className="botActivityDecorator_content">{children}</div>
      </div>
    );
  };

  function disableUsedForms(action)
  {
    if(action.payload.activity.value.intent === "reportLostAsset")
    {
        if(action.payload.activity.hasOwnProperty("recipient"))
          {
            disableLostOrStolenForm();
          } else {
            setTimeout(function() {
                disableLostOrStolenForm();
              }, 600);
          } 
    }

    if(action.payload.activity.value.intent === "submitFeedbackForm")
    {
      if(action.payload.activity.hasOwnProperty("recipient"))
      {
        disableFeedbackForm();
      }else
      {
        setTimeout(function()
        {
          disableFeedbackForm()
        }, 600);
      }
    }

    if( action.payload.activity.value.intent === "createIncidentForms")
    {
      if(action.payload.activity.hasOwnProperty("recipient"))
      {
        disableCreateTicketForm();
      }else
      {
        setTimeout(function()
        {
          disableCreateTicketForm()
        }, 600);
      }
    }

    if( action.payload.activity.value.intent === "cmdbDataForm")
    {
      if(action.payload.activity.hasOwnProperty("recipient"))
      {
        disableCMDBDataForm();
      }else
      {
        setTimeout(function()
        {
          disableCMDBDataForm()
        }, 600);
      }
    }

    if( action.payload.activity.value.intent === "mfaResetForm")
    {
      if(action.payload.activity.hasOwnProperty("recipient"))
      {
        disableMFAResetForm();
      }else
      {
        setTimeout(function()
        {
          disableMFAResetForm()
        }, 600);
      }
    }
  }

  function disableFeedbackForm()
  {
    $('.webchat__basic-transcript__activity--from-bot #reason textarea').each(function( i, element ) {
      $(element).attr("disabled", "disabled");
    });

    $('.webchat__basic-transcript__activity--from-bot #rating select').each(function( i,element ) {
      $(element).attr("disabled", "disabled");
    });
  
    $('.webchat__basic-transcript__activity--from-bot #rating').each(function( i,element ) {
      $(element).parent().find('button').attr("disabled", "disabled").addClass('ac-pushButton-disabled').removeClass('ac-pushButton');
    });
  }
  
  function disableCreateTicketForm() 
  {
    $('.webchat__basic-transcript__activity--from-bot #description textarea').each(function( i, element ) {
      $(element).attr("disabled", "disabled");
    });

    $('.webchat__basic-transcript__activity--from-bot #isUrgent input').each(function( i,element ) {
      $(element).attr("disabled", "disabled");
    });


    $('.webchat__basic-transcript__activity--from-bot #description').each(function( i,element ) {
      $(element).parentsUntil('div.ac-columnSet').parent().parent().find('button').attr("disabled", "disabled").addClass('ac-pushButton-disabled').removeClass('ac-pushButton');
    });
  }

  function disableCMDBDataForm()
  {
    $('.webchat__basic-transcript__activity--from-bot #reason textarea').each(function( i, element ) {
      $(element).attr("disabled", "disabled");
    });

    $('.webchat__basic-transcript__activity--from-bot #reason').each(function( i,element ) {
      $(element).parentsUntil('div.ac-columnSet').parent().parent().find('button').attr("disabled", "disabled").addClass('ac-pushButton-disabled').removeClass('ac-pushButton');
    });
  }

  function disableMFAResetForm()
  {
    $('.webchat__basic-transcript__activity--from-bot #reason textarea').each(function( i, element ) {
      $(element).attr("disabled", "disabled");
    });

    $('.webchat__basic-transcript__activity--from-bot #reason').each(function( i,element ) {
      $(element).parentsUntil('div.ac-columnSet').parent().parent().find('button').attr("disabled", "disabled").addClass('ac-pushButton-disabled').removeClass('ac-pushButton');
    });
  }

  function disableLostOrStolenForm() {
    $('.webchat__basic-transcript__activity--from-bot #whatStolen textarea').each(function(i, element)
    {
      $(element).attr('disabled', 'disabled');
    });

    $('.webchat__basic-transcript__activity--from-bot #howStolen textarea').each(function(i, element)
    {
      $(element).attr('disabled', 'disabled');
    });
    $('.webchat__basic-transcript__activity--from-bot #contact input').each(function(i, element)
    {
      $(element).attr('disabled', 'disabled');
    });

    $('.webchat__basic-transcript__activity--from-bot #address textarea').each(function(i, element)
    {
      $(element).attr('disabled', 'disabled');
    });

    $('.webchat__basic-transcript__activity--from-bot #whatStolen').each(function( i,element ) {
      $(element).parentsUntil('div.ac-columnSet').parent().parent().find('button').attr("disabled", "disabled").addClass('ac-pushButton-disabled').removeClass('ac-pushButton');
    });
  }

  function getDecrementedActivityId(activityID) {
    const activityIdSeperator = activityID.split('|');
    const activityIDAfterPipeSymbol = activityIdSeperator[1];
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const decrementActivityNumber = zeroPad(activityIDAfterPipeSymbol - 1, activityIDAfterPipeSymbol.length);
    const decrementActivityId = `${activityIdSeperator[0]}|${decrementActivityNumber}`;
    return decrementActivityId;
  }

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent'
      }),
    []
  );

  // To learn about reconnecting to a conversation, see the following documentation:
  // https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-reconnect-to-conversation?view=azure-bot-service-4.0

  const handleFetchToken = useCallback(async () => {
    //Login Using MSAL Popup Window
    try {
      setInterval(async () => {
        const cachedToken = window.sessionStorage.getItem('MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN');
        var decodedToken = jwtDecode.default(cachedToken, { complete: true });
        var dateNow = new Date();
        if (((decodedToken.exp * 1000) - 600000) < dateNow.getTime()) {
          try {
            // console.log('Token Expire window reload');
            window.sessionStorage.clear();
            window.location.reload();

          } catch (error) {
            let response;
            if (isInst) {
              // await instance.loginRedirect(authConfig.loginRequest)
              instance.handleRedirectPromise().then(async authResult => {
                // Check if user signed in 
                const account = instance.getActiveAccount();
                if (!account) {
                  // redirect anonymous user to login page 
                  await instance.loginRedirect(authConfig.loginRequest);
                }
              }).catch(err => {
                // TODO: Handle errors
                console.log(err);
              });
            } else {
              response = await instance.loginPopup(authConfig.loginRequest)
            }
            if (response.accessToken && response.accessToken !== 'null' && response.accessToken !== 'undefined') {
              window.sessionStorage.setItem("MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN", response.accessToken);
            }
          }
        }
      }, 60000);

      // set the token into session storage
      let accountR = instance.getActiveAccount();
      let response = await instance.acquireTokenSilent({
        ...authConfig.silentLoginRequest,
        account: accountR
      });
      appInsights.trackEvent({
        name: 'EVALoginSuccessful',
        properties: {
          email: response.account.username,
          name: response.account.name,
          userId: response.account.localAccountId,
          requestDate: new Date(),
          browser: browserDetect()
        }
      });
      if (response.accessToken && response.accessToken !== 'null' && response.accessToken !== 'undefined') {
        window.sessionStorage.setItem("MICROSOFT_DISPATCH_ACCESS_TOKEN", response.accessToken);
      }
    } catch (error) {
      try {
        let response;
        if (isInst) {
          // handle auth redired/do all initial setup for msal
          instance.handleRedirectPromise().then(async authResult => {
            // Check if user signed in 
            const account = instance.getActiveAccount();
            if (!account) {
              // redirect anonymous user to login page 
              await instance.loginRedirect(authConfig.loginRequest);
            }
            try {
              instance.acquireTokenSilent({
                ...authConfig.loginRequest,
                account: account
              }).then(async (response)=>{
                if (response.accessToken && response.accessToken !== 'null' && response.accessToken !== 'undefined') {
                  window.sessionStorage.setItem("MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN", response.accessToken);
                }
              });
              
            } catch (error) {
              instance.acquireTokenRedirect({
                ...authConfig.loginRequest,
                account: account
              }).then((response)=>{
                if (response.accessToken && response.accessToken !== 'null' && response.accessToken !== 'undefined') {
                  window.sessionStorage.setItem("MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN", response.accessToken);
                }
              });;
              
            }
          }).catch(err => {
            // TODO: Handle errors
            console.log(err);
          });
        } else {
          response = await instance.loginPopup(authConfig.loginRequest)
        }
        setIsloginFailure(true);
        appInsights.trackEvent({
          name: 'EVALoginSuccessful',
          properties: {
            email: response.account.username,
            name: response.account.name,
            userId: response.account.localAccountId,
            requestDate: new Date(),
            browser: browserDetect()
          }
        });
        if (response.accessToken && response.accessToken !== 'null' && response.accessToken !== 'undefined') {
          window.sessionStorage.setItem("MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN", response.accessToken);
        }
      } catch (error) {
        // console.log('inside catch');
        appInsights.trackEvent({
          name: 'EVALoginFailure',
          properties: {
            requestDate: new Date(),
            browser: browserDetect(),
            reason: JSON.stringify(error),
          }
        });
        setIsloginFailure(true);
      }
    }

    setTimeout(async () => {
      await fetchUserDetails();

      let user = instance.getActiveAccount();
      
      let responseR;
      try {
        if (isInst) {
          try {
            responseR = await instance.acquireTokenSilent({
              ...authConfig.silentLoginRequest,
              account: user
            });
          } catch (error) {
            responseR = await instance.acquireTokenRedirect({
              ...authConfig.silentLoginRequest,
              account: user
            });
          }
        } else {
          try {
            responseR = await instance.acquireTokenSilent({
              ...authConfig.silentLoginRequest,
              account: user
            });
          } catch (error) {
            responseR = await instance.acquireTokenPopup({
              ...authConfig.silentLoginRequest,
              account: user
            });
          }
        }
      } catch (err) {
        console.log(err);
        user = 'popUpBlock';
      }
      if (responseR.accessToken && responseR.accessToken !== 'null' && responseR.accessToken !== 'undefined') {
        window.sessionStorage.setItem("MICROSOFT_DISPATCH_ACCESS_TOKEN", responseR.accessToken);
      }

      if (user === undefined) {
        user = await getUserRecursive();
        setUserID(user);
        // window.location.reload();
      } else if (user === 'popUpBlock') {
        setIsloginFailure(true);
      } else {
        setUserID(user);
        //Setting up the Directline Token //
        const directlineUserID = user.localAccountId;
        if (!token) {
          const { token } = await directLineServices.getDirectlineToken(directlineUserID);
          setToken(token.token);
        }

        let evaBaseURL = baseURLMap.getEVABaseURL();
        evaBaseURL = evaBaseURL + '/api/msgraph/lang';
        let userLangFormAPI = { langFlag: 'false', userSaveLang: 'en' };
        if (!isInst) {
          userLangFormAPI = await getUserSavedLangFromBlobStorage(evaBaseURL, user);
        }
        // if(userLangFormAPI !== '') {
        //   setUserLang(userLangFormAPI);
        // }

        addClassToUploadAttchmentIcon();

        //Adding Hamburger Menu in the Webchat//
        let elementExists = document.getElementById("openhamburgermenu");
        if (!elementExists) {
          renderHamburger(userLangFormAPI);
          let evaBaseURL = baseURLMap.getEVABaseURL();
          evaBaseURL = evaBaseURL + '/api/notification/status';
          if (!isInst && directlineUserID !== null && directlineUserID !== undefined) {
            const record = await getStatus(evaBaseURL, directlineUserID);
            if (record !== null && record !== undefined) {
              if (record.whatIsNew !== null && record.whatIsNew !== undefined && record.whatIsNew === true) {
                if (record.whatIsNewPoints !== null && record.whatIsNewPoints !== undefined && record.whatIsNewPoints) {
                  // create ul element and set the attributes.
                  var pointUl = document.createElement('ul');
                  for (let i = 0; i < record.whatIsNewPoints.length; i++) {
                    var pointLi = document.createElement('li');
                    pointLi.innerHTML = record.whatIsNewPoints[i];
                    pointUl.appendChild(pointLi);
                  }
                }
                const whatIsNewLang = hambugerMenu.getWhatsNewFeature(userLangFormAPI);
                $('.minimizable-web-chat .chat-box').prepend(whatIsNewLang);
                document.getElementById("newFeaturePoints").appendChild(pointUl);
                // Handle click options inside notification
                $("#notification button").on('click', function () {
                  disp({
                    type: 'DIRECT_LINE/POST_ACTIVITY',
                    meta: {
                      method: "imBack"
                    },
                    payload: {
                      activity: {
                        text: $(this).text(),
                        textFormat: "plain",
                        type: "message"
                      }
                    }
                  });
                  $('#notification').hide();
                });
                $("#notification .notification_close_btn").on('click', function () {
                  $('#notification').hide();
                });
                setTimeout(async () => {
                  $('#notification').hide();
                }, 10000);
              }
            }
          }
        }
      }
    }, 1000);

    let noOfTries = 0;
    async function getUserRecursive() {

      await fetchUserDetails();

      let user = instance.getActiveAccount();
      let responseR;
      try {
        if (isInst) {
          try {
            responseR = await instance.acquireTokenSilent({
              ...authConfig.loginRequest,
              account: user
            });
          } catch (error) {
            responseR = await instance.acquireTokenRedirect({
              ...authConfig.loginRequest,
              account: user
            });
          }
        } else {
          try {
            responseR = await instance.acquireTokenSilent({
              ...authConfig.silentLoginRequest,
              account: user
            });
          } catch (error) {
            responseR = await instance.acquireTokenPopup({
              ...authConfig.silentLoginRequest,
              account: user
            });
          }
        }
      } catch (err) {
        console.log(err);
        user = 'popUpBlock';
      }
      if (responseR.accessToken && responseR.accessToken !== 'null' && responseR.accessToken !== 'undefined') {
        window.sessionStorage.setItem("MICROSOFT_DISPATCH_ACCESS_TOKEN", responseR.accessToken);
      }

      if (user === undefined && noOfTries < 2 && user !== 'popUpBlock') {
        noOfTries = noOfTries + 1;
        getUserRecursive();
      } else {
        return user;
      }

    }

  }, []);

  async function fetchUserDetails() {
    let endPoint = graphConfig.graphMeEndpoint;
    let msalToken = window.sessionStorage.getItem('MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN');
    let userInfo = await getUserDetails(endPoint, msalToken);
    setUserInfo(userInfo);
  }

  function addClassToUploadAttchmentIcon() {
    var uploadAttchmentElement = $('button[title="Upload file"]');

    //If Upload Attachment File Container is not available in DOM, Lets retry after 100 MiliSeconds.
    if (uploadAttchmentElement.length < 1) {
        setTimeout(addClassToUploadAttchmentIcon, 100);
        return;
    }

    $('button[title="Upload file"]').parent().addClass('attachment_icon');
  }

  function renderHamburger(lang) {
    // console.log('render Hambuger menu');
    let mappedMenuList = hambugerMenu.getMenuList(lang);
    if (isInst) {
      mappedMenuList = hambugerMenu.getCDSMenuList();
    }

    var appendHamburgerIcon = function () {
      var hamburgerContainerElement = $('.webchat__send-box__main');

      //If Hamburger Container is not available in DOM, Lets retry after 100 MiliSeconds.
      if (hamburgerContainerElement.length < 1) {
        setTimeout(appendHamburgerIcon, 100);
        return;
      }

      $('.webchat__send-box__main').prepend('<div id="openhamburgermenu"></div>');
      $("#openhamburgermenu").append(mappedMenuList);
      $('#hamburger_options').hide();
      $('.webchat__icon-button').hide();
      $('.webchat--css-ehnfd-1ldy6fq').hide();
      $('#openhamburgermenu').on('click', function () {
        if ($('#hamburger_options').hasClass('selected')) {
          $('#hamburger_options').removeClass('selected')
        } else {
          $('#hamburger_options').addClass('selected')
        }
        if ($('#hamburger_options').hasClass('selected')) {
          $('#hamburger_options').show();
        } else {
          $('#hamburger_options').hide();
        }
      });

      // Handle click options inside hamburger
      $("#hamburger_options li").on('click', function () {
        disp({
          type: 'DIRECT_LINE/POST_ACTIVITY',
          meta: {
            method: "imBack"
          },
          payload: {
            activity: {
              text: $(this).text(),
              textFormat: "plain",
              type: "message"
            }
          }
        });
        $('#hamburger_options').hide();
      });
      // Hide the meny when user clicks outside the menu
      $(document).on('mouseup', function (e) {
        var container = $("#hamburger_options");
        if (!container.is(e.target) &&
          container.has(e.target).length === 0) {
          container.hide();
        }
      });
    }

    appendHamburgerIcon();
  }

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === 'left' ? 'right' : 'left');
  }, [setSide, side]);

  const BotLivePersonActivityDecorator = ({ children }) => {
    return (
      <div className="liveChat_conversation">{children}</div>
    );
  };

  const activityMiddleware = () => next => (...setupArgs) => {
    const [card] = setupArgs;
    if (card.activity.channelId === 'directline' && card.activity.from.role === 'bot'
      && card.activity.text !== undefined && card.activity.text !== null && card.activity.text !== '' &&
      (card.activity.text === 'Here are the best matches' || card.activity.text === 'Dit zijn de beste matches' || card.activity.text === 'Aquí están los mejores partidos')) {
      $("button.react-film__flipper--right").on('click', function () {
        setTimeout(() => {
          var siblingLength = $(this).siblings(".react-film__flipper--left").find(".react-film__main__slider--hide").length;
          if (siblingLength === 0) {
            $(this).closest('.botActivityDecorator_buttonBar + .botActivityDecorator_content').prev().css('z-index', 0);
          }
        }, 100);
      });
      $("button.react-film__flipper--left").on('click', function () {
        setTimeout(() => {
          var siblingLength = $(this).find(".react-film__main__slider--hide").length;
          if (siblingLength === 1) {
            $(this).closest('.botActivityDecorator_buttonBar + .botActivityDecorator_content').prev().css('z-index', 2);
          }
        }, 200);
      });
      return (...renderArgs) => (
        <BotActivityDecorator activityID={card.activity.id} key={card.activity.id}>
          {next(card)(...renderArgs)}
        </BotActivityDecorator>
      );
    } else if (card.activity.type === 'message' && card.activity.channelId === 'directline' && card.activity.from.role === 'bot' &&
      card.activity.suggestedActions && card.activity.suggestedActions.actions && card.activity.suggestedActions.actions[0]  && card.activity.suggestedActions.actions[0].title !== undefined && card.activity.suggestedActions.actions[0].title !== null && card.activity.suggestedActions.actions[0].title !== '' &&
      (card.activity.suggestedActions.actions[0].title === 'End Live Chat' || card.activity.suggestedActions.actions[0].title === 'Finalizar el chat in vivo' || card.activity.suggestedActions.actions[0].title === 'Finalizar el chat en vivo' || card.activity.suggestedActions.actions[0].title === 'Live chat beëindigen')) {
      return (...renderArgs) => (
        <BotLivePersonActivityDecorator activityID={card.activity.id} key={card.activity.id}>
          {next(card)(...renderArgs)}
        </BotLivePersonActivityDecorator>
      );
    } else {
      return next(card);
    }
  };


  if (isInst) {
    setTimeout(async () => {
      let msalToken = window.sessionStorage.getItem('MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN')
      if ((msalToken === null || msalToken === undefined)) {
        if (!isSignout) {
          // await instance.loginRedirect(authConfig.loginRequest)
          instance.handleRedirectPromise().then(authResult => {
            // Check if user signed in 
            const account = instance.getActiveAccount();
            if (!account) {
              // redirect anonymous user to login page 
              instance.loginRedirect(authConfig.loginRequest);
            }
          }).catch(err => {
            // TODO: Handle errors
            console.log(err);
          });
        }
      } else if (msalToken) {
        handleMaximizeButtonClick();
      }
    }, 3000);
  }
  // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
  //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
  //       When minimized, we still want to maintain that connection while the UI is gone.
  //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

  const handleSignoutEvent = useCallback(async () => {
    disp({
      type: 'DIRECT_LINE/POST_ACTIVITY',
      meta: {
        method: "imBack"
      },
      payload: {
        activity: {
          text: 'Sign out',
          textFormat: "plain",
          type: "message"
        }
      }
    });
    setIsSignout(true);
    window.localStorage.clear();
    // window.sessionStorage.clear();
    cookie.setCookie('webchatJoin', false);
  });

  // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
  //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
  //       When minimized, we still want to maintain that connection while the UI is gone.
  //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

  return (
    <div className="minimizable-web-chat webchat_allignmnet">
      {((isInst && isSignout) || isInst && !isSignout) && <div className="sso__upperRight">
        {
          <MicrosoftGraphProfileMenu
            userId={isSignout ? null : userID && userID.localAccountId ? userID.localAccountId : null}
            name={isSignout ? null : userID && userID.name ? userID.name : null}
            onSignIn={!isSignout}
            handleSignoutEvent={handleSignoutEvent}
            msgraphToken={window.sessionStorage.getItem("MICROSOFT_GRAPH_OAUTH_ACCESS_TOKEN")}
          />
        }
      </div>}

      {!isInst && minimized && (
        <button className="maximize placeholder" onClick={handleMaximizeButtonClick}>
          <span className="eva-icon">EVA-CHAT-ICON-TITLE</span>
          {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
        </button>
      )}
      {(loaded || isInst) && (
        <div className={classNames(isInst ? 'chat-box' : side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : '')}>
          <header>
            <div className="chat_hd">
              <span className="chat_ic hd_chat_ic_it"></span><span className="hd_title">EVA-TITLE</span>
            </div>
            <div className="filler" />
            <button className="switch" onClick={handleSwitchButtonClick}>
              <span className="ms-Icon ms-Icon--Switch" />
            </button>
            <button className="minimize" onClick={handleMinimizeButtonClick}>
              <span className="ms-Icon ms-Icon--ChromeMinimize" />
            </button>
          </header>
          <WebChat
            className="react-web-chat"
            onFetchToken={handleFetchToken}
            store={store}
            styleSet={styleSet}
            token={token}
            isloginFailure={isloginFailure}
            userID={userID}
            userInfo={userInfo}
            activityMiddleware={activityMiddleware}
            appInsights={appInsights}
          />
        </div>

      )}

      {isInst && !loaded && (
        <div className="connect-spinner">
          <div className="content">
            <div className="icon">
              <span className="ms-Icon ms-Icon--Robot" />
            </div>
            <p>Please wait while we are connecting.</p>
          </div>
        </div>
      )
      }
    </div>
  );
};

export default MinimizableWebChat;
