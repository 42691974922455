import { ApplicationInsights } from '@microsoft/applicationinsights-web';
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @return {void}
     */
    const initialize = (instrumentationKey) => {

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                enableAutoRouteTracking: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                enableAjaxErrorStatusText: true,
            }
        });

        appInsights.loadAppInsights();
    };

    return {appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;