
function getLocalStoredValue(lname) {

   let item = window.localStorage.getItem(lname);
   item = JSON.parse(item);
   //console.log('ITEM -->', item);
   var currentTime = new Date();
   currentTime = currentTime.getTime();

   if((item) && (item.conversationId) && (item.conversationId !== '') && 
        (item.conversationExpire) && (currentTime <= item.conversationExpire) &&
        (item.conversationToken) && (item.conversationToken !== '') ) {
        return item;
   } else {
        removeItemFromLocalStorage(lname);
        return {}
   }

}

function setValueIntoLocalStorage(lname, lvalue) {
    window.localStorage.setItem(lname, JSON.stringify(lvalue)); 
}

function removeItemFromLocalStorage(lname) {
    window.localStorage.removeItem(lname);
}

const customlocalStorage = {
    getLocalStoredValue: getLocalStoredValue,
    setValueIntoLocalStorage: setValueIntoLocalStorage,
    removeItemFromLocalStorage: removeItemFromLocalStorage
}

export default customlocalStorage;